@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0 20px;
  background: #f1f1f1;
}
body, html, input, textarea {
  font-family: "Poppins";
}
h1,h2,h3,h4 {
  font-weight: 500;
}
p {
  font-weight: 300;
}
p, a { font-size: .9em; }
b, strong { font-weight: 700; }
.App {
  font-size: 1.2em;
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  padding: 20px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #0080ff;
  border-bottom: 1px dotted;
}
.site-header {
  display: flex;
  /* overflow: hidden; */
  align-items: center;
  margin: 0 0 60px 0;
}
.site-header h1 {
  font-size: 1.8em;
  color: #000;
  margin: 0;
}
.site-header a {
  color: #000;
  border-bottom: 0;
}
.filter-reviews {
  text-align: left;
}
.filter-reviews a {
  margin-left: 10px;
}
.logo {
  flex-grow: 1;
}
.logo h1 {
  display: inline-block;
  border-bottom: 0;
}
.site-footer {
  font-size: .8em;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 20px;
}
.site-content {
  min-height: 800px;
}
.badge {
  display: inline-block;
  font-size: .7em;
  font-weight: 300;
  padding: 0.2rem 0.7rem 0.2rem;
  margin: 2px 5px 2px 0;
  border-radius: 20px;
  color: #fff;
  background-color: #0080ff;
}
.tag {
  display: inline-block;
  font-size: .65em;
  font-weight: 300;
  padding: 0.1em 0.9em 0;
  margin: 2px 4px 2px 0;
  border-radius: 20px;
  /* color: #999 !important;
  background-color: #eee; */
  color: #0080ff !important;
  background-color: #e5f2ff;
  /* color: #fff !important;
  background-color: #0080ff; */
}
.tag a {
  border-bottom: 0;
}
.spinner {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 100px;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: 5px !important; }
.mb-2 { margin-bottom: 10px !important; }
.mb-3 { margin-bottom: 15px !important; }
.mb-4 { margin-bottom: 20px !important; }
.mb-5 { margin-bottom: 25px !important; }
.mb-6 { margin-bottom: 30px !important; }
.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: 5px !important; }
.mt-2 { margin-top: 10px !important; }
.mt-3 { margin-top: 15px !important; }
.mt-4 { margin-top: 20px !important; }
.mt-5 { margin-top: 25px !important; }
.mt-6 { margin-top: 30px !important; }
.ml-0 { margin-left: 0 !important; }
.ml-1 { margin-left: 5px !important; }
.ml-2 { margin-left: 10px !important; }
.ml-3 { margin-left: 15px !important; }
.ml-4 { margin-left: 20px !important; }
.ml-5 { margin-left: 25px !important; }
.ml-6 { margin-left: 30px !important; }
.mr-0 { margin-right: 0 !important; }
.mr-1 { margin-right: 5px !important; }
.mr-2 { margin-right: 10px !important; }
.mr-3 { margin-right: 15px !important; }
.mr-4 { margin-right: 20px !important; }
.mr-5 { margin-right: 25px !important; }
.mr-6 { margin-right: 30px !important; }
.fw-lighter { font-weight: 200 !important; }
.fw-light { font-weight: 300 !important; }
.fw-normal { font-weight: 400 !important; }
.fw-semibold { font-weight: 600 !important; }
.fw-bold { font-weight: 700 !important; }
.w-100 { width: 100% !important; }
.text-primary {
  color: #0080ff !important;
}
.link-dotted {
  border-bottom: 1px dotted !important;
}
.bg-primary {
  background-color: #0080ff !important;
  color: #fff !important;
}

/* review list */
.review-card {
  background: white;
  margin: 60px auto;
  padding: 1px 20px 30px 90px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 10%);
  box-sizing: border-box; 
}
.review-card .rating {
  position: absolute;
  top: -20px;
  left: -20px;
  background: #0080ff;
  font-size: 3em;
  width: 90px;
  height: 90px;
  text-align: center;
  color: white;
  border-radius: 50%;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 10%);
}
.review-card h2 {
  margin-bottom: 0;
  line-height: 1.3em;
}
.review-card small {
  margin-right: 10px;
  color: #777;
}

/* auth */
.custom-card {
  background: white;
  margin: 25px auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 10%);
  box-sizing: border-box; 
}
.custom-card-small {
  max-width: 560px;
}
.custom-card-title {
  font-size: 1.3em;
  margin: 0 0 40px 0;
  padding-bottom: 12px;
  border-bottom: 1px dotted #ced4da;
}
.custom-card-top {
  padding: .75rem 1.25rem;
  font-size: .8em;
  font-weight: 300;
  margin: 25px auto;
  color: #0080ff;
  background-color: #e5f2ff;
  border: 0 solid #cce5fe;
  border-radius: .3125rem;
  box-shadow: 0 3px 6px 0 rgba(140,152,164,.25);
  box-sizing: border-box; 
}
.custom-card-bottom {
  font-size: .9em;
  font-weight: 300;
  margin: 25px auto;
  box-sizing: border-box; 
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger-outline {
  color: #dc3545;
  background-color: #fff;
  border-color: #fff;
}


/* form */
label {
  display: inline-block;
  margin-bottom: .3rem;
  font-size: .9em;
}
label small {
  color: #777;
  font-size: .7em;
  font-weight: 300;
}
input,
select,
textarea {
  width: 100%;
  height: 48px;
  border-radius: .3125rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border: 1px solid #ced4da;
  background-color: #fff;
  box-sizing: border-box;  
}
textarea {
  height: 160px;
  vertical-align: top;
}
select {
  height: 48px;
}
select.custom-select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiIGZpbGw9IiMwMDAwMDAiLz4KICA8L2c+Cjwvc3ZnPgo=);
  background-size: 12px 12px;
}
small, .small {
  font-size: .7em;
}
.text-muted {
  color:rgba(0, 0, 0, .5)
}
.invalid {
  border: 1px solid #dc3545;
  border-radius: 0.3125rem;
}
.invalid-feedback {
  color: #dc3545;
  font-size: .7em;
  font-weight: 300;
}
.hide-show-password {
  display: block;
  position: relative;
}
.hide-show-password .eye-icon {
  display: block;
  position: absolute;
  right: 16px;
  top: 12px;
  opacity: .4;
}
.hide-show-password .eye-icon::after {
  display: block;
  content: '';
  background: url('./eye-open.png') no-repeat scroll 0 0 transparent;
  width: 25px;
  height: 25px;
  background-size: 25px;
  cursor: pointer;
}
.hide-show-password .eye-icon.shown::after {
  background-image: url('./eye-closed.png');
}
input:focus,
textarea:focus,
select:focus {
  outline: 0 none;
  border:1px solid #0080ff;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
.btn {
  cursor: pointer;
  display: inline-block;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #0080ff;
  border: 1px solid transparent;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3125rem;
}
.btn:hover,
.btn:focus {
  background-color: #006cd8;
}

/* container vertical */
.container-vertical {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 300px);
}
.container-vertical-content {
  flex-grow: 1;
}


/* The customcheck */
.customcheck {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: .8em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.customcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: auto;
  height: 18px;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 4px;
}
/* On mouse-over, add a grey background color */
.customcheck:hover input ~ .checkmark {
  border: 1px solid #0080ff;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}
/* When the checkbox is checked, add a blue background */
.customcheck input:checked ~ .checkmark {
  background-color: #0080ff;
  border: 1px solid #0080ff;
}
/* Create the checkmark/indicator (hidden when not checked) */
.customcheck .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.customcheck input:checked ~ .checkmark::after {
  display: block;
}
/* Style the checkmark/indicator */
.customcheck .checkmark::after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* The small customcheck */
.customcheck-sm {
  padding-left: 25px;
}
.customcheck-sm .checkmark {
  top: 3px;
  height: 15px;
  width: 15px;
}
.customcheck-sm .checkmark::after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 7px;
}


/* pagination */
.pagination {
  list-style-type:none;
  display: block;
  padding: 0;
  text-align: center;
}
.pagination li {
  display: inline-block;
}
.pagination a {
  display: inline-block;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center; 
  margin: 0 5px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  color:rgb(0 0 0 / 90%);
}
.pagination li.dotted a {
  background-color: transparent;
  margin: 0;
}
.pagination li.dotted.disabled a {
  background-color: transparent;
  color: rgb(0 0 0 / 100%);
}
.pagination li.active a {
  background-color: #0080ff;
  color: #fff;
}
.pagination li.disabled a {
  color: rgb(0 0 0 / 40%);
  pointer-events: none;
  background-color: #fff;
}

/* paging */
.paging {
  padding: 1em 0;
  display: flex;
  justify-content: center;
}
.paging-carrier {
  box-shadow: 0 0.1rem 0.2rem 0 rgb(0 0 0 / 5%);
  border-radius: 23px;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  width: 300px;
  overflow: hidden;
}
.paging-carrier svg {
  padding: 8px 20px 6px 20px;
  height: 34px;
  width: 34px;
}
.paging-carrier div:first-child {
  border-right: 1px solid #f5f5f5;
}
.paging-carrier div:nth-child(3) {
  border-left: 1px solid #f5f5f5;
}
.paging-carrier > div {
  width: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paging-carrier span {
  color: #333;
  font-size: 21px;
  font-weight: 600;
}
.paging-carrier a {
  padding: 0;
  margin: 0;
  line-height: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paging-carrier a:hover {
  background-color: rgba(0, 0, 0, .02);
}
.paging-carrier a:hover svg {
  stroke:#000
}

/* flex container */
.flex-container {
  display: flex;
  overflow: hidden;
}
.flex-container .first-col {
  flex-grow: 1;
  padding-right: 30px;
}

/* media query */
.show-desktop { display: block; }
.show-mobile { display: none; }
@media (max-width: 767px) {
  .show-desktop { display: none; }
  .show-mobile { display: block; }
  .flex-container { display: block; }
  .flex-container .first-col { margin-bottom: 20px; }
}
@media (max-width: 600px) {
  body { margin: 0 0; }
  p, a { font-size: .85em; }
  .review-card { padding-left: 30px; }
  .review-card .rating {
    top: 0;
    left: 0;
    padding: 2px 0;
    background: #0080ff;
    font-size: 2em;
    width: 100%;
    height: 48px;
    line-height: 50px;
    text-align: center;
    color: white;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 10%);
  }
  .review-card h2 { margin-top: 65px; font-size: 1.35em; line-height: 1.2em; margin-bottom: 5px; }
  .paging-carrier { width: 100%; }
  .tag { padding: 0.1em 0.9em 0.1em; }
  .w-md-editor-toolbar { padding: 10px 5px 10px 5px !important; }
}

/* .dropdown {
  background: transparent url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiIGZpbGw9IiMwMDAwMDAiLz4KICA8L2c+Cjwvc3ZnPgo=) right no-repeat;;
  background-size: 16px 16px;
  padding-right: 20px;
  cursor: pointer;
} */

.dropdown-icon {
  cursor: pointer;
}
.dropdown-icon::after {
  content: '';
  border: solid;
  border-width: 0 1px 1px 0;
  padding: 4px;
  transform: rotate(45deg);
  float: right;
  margin: 6px 5px 0 10px;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 20px;
  z-index: 1;
  right: 0;
  text-align: right;
  border-radius: 5px;
}
.dropdown-content ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.dropdown-content ul li {
  padding: 4px 0;
}
.dropdown:hover .dropdown-content,
.dropdown:active .dropdown-content {
  display: block;
}
.dropdown:hover .dropdown-icon::after,
.dropdown:active .dropdown-icon::after {
  transform: rotate(225deg);
  margin: 12px 5px 0 10px;
}


.EasyMDEContainer .CodeMirror {
  height: 240px !important;
  
}